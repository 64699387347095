<template>
  <el-descriptions title="用户信息">
    <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
    <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
    <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
    <el-descriptions-item label="备注">
      <el-tag size="small">学校</el-tag>
    </el-descriptions-item>
    <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>
  </el-descriptions>
</template>

<script>

export default {
  name: "orgdetail",
  data(){
    return {
      orgdtl: {}

    }
  },
  mounted() {
    this.orgdtl = JSON.parse(this.$route.params.orgitem)
  }
}
</script>

<style scoped>

</style>
