<template>
    <div style="background: #F1F2F8">
        <div class="topc">
            <img class="image" style="margin-left: 20%;"  src="../assets/logo.png" @click="goHome">

        </div>
        <el-main style="height: 100%;background: #FFFFFF;margin-left: 20%;margin-right: 20%;margin-top: 30px;border-radius: 5px;">
            <div style="font-size: 18px;color: #333333">{{newsdtl.title}}</div>
            <div style="display: flex">
                <div style="font-size: 14px;color: #666666;margin-top: 20px;">本文来源：{{newsdtl.source}}</div>
                <div style="font-size: 14px;color: #666666;margin-top: 20px;margin-left: 50px;">作者：{{newsdtl.author}}</div>
            </div>
            <div style="margin-top: 20px;">
                <img :src="newsdtl.newsavatar">
            </div>
            <div style="font-size: 14px;color: #666666;line-height: 20px;">
                <span v-html="newsdtl.content"></span>
            </div>
        </el-main>
 </div>
    <el-footer>
        <div>©2021 北京慈典信息服务有限公司  <a target="_blank" href="https://beian.miit.gov.cn/" style="color: #FFFFFF;margin-left: 15px;text-decoration:none;" >ICP备案：京ICP备2021027898号</a>

            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802037567" style="margin-left: 15px;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img src="../assets/beian.png" style="float:left;"/>
                <p style="float:left;height:20px;margin-left: 15px;margin: 4px 0px 0px 0px; color:#FFFFFF;">京公网安备 11010802037567号</p>
            </a>

        </div>
    </el-footer>
</template>

<script>

import axios from "axios";

export default {
  name: "newsdetail",
  data(){
    return {
      newsdtl: {},
      newinfo_id: 0

    }
  },
  mounted() {
    console.log('=this.$route.newsitem=' + JSON.parse(this.$route.query.id))
    this.newinfo_id = JSON.parse(this.$route.query.id)
    this.findByid()
  },
    methods: {
        goHome() {
            this.$router.push({
                    name: 'Home'
                }
            );
        },
    findByid(){
        let that = this
        axios.get('https://api.gycidian.com/api/mobileapi/getNewsInfoById',
            {
                params: {
                    id: this.newinfo_id
                }
            }
        ).then(response => {
            console.log(response.data)

            that.newsdtl = response.data
            console.log(that.projdtl.propName)

        }, response => {
            console.log('error=' + response)
        })
    }
    }
}
</script>

<style scoped>

    .headers{
        background: linear-gradient(#4169E1,#87CEFA);
        height: 120px;
    }
</style>
