import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import resultList from '../views/resultList'
import orgInfo from "../views/orgInfo/orgInfo";
import orgRsltList from '../views/orgRsltList'
import newsDetail from '../views/newsdetail'
import orgDetail from '../views/orgdetail'
import projDetail from '../views/projdetail'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    name: 'resultList',
    path: '/resultList',
    component: resultList
  },
  {
    path: '/orgInfo/orgInfo',
    name: 'orgInfo',
    component: orgInfo
  },
  {
    path: '/orgRsltList',
    name: 'orgRsltList',
    component: orgRsltList
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: newsDetail
  },
  {
    path: '/orgdetail',
    name: 'orgdetail',
    component: orgDetail
  },
  {
    path: '/projdetail',
    name: 'projdetail',
    component: projDetail
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
