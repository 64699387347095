<template>
    <div style="background: #F1F2F8">
        <div class="topc">
            <img class="image" style="margin-left: 20%;"  src="../../assets/logo.png" >
        </div>
        <div style="margin-top: -100px;margin-left: 65%">
            <div class="gohome"  @click="clickhome">首页</div>
            <div class="goorg">机构申请入驻</div>
            <div class="gologin" style="padding-right: 18%;" @click="orgLogin('http://platform.gycidian.com/')" >机构登录</div>
        </div>
        <el-container style="background: #FFFFFF;margin-left: 15%;margin-right: 15%;margin-top: 30px;border-radius: 5px;">
        <el-main >
            <div style="font-size: 18px;color: #1F2D3D;text-align: center;font-weight: 600;">欢迎入驻慈典平台,请填写如下资料</div>
            <el-form ref="regForm" :model="regForm" :rules="loginRules" class="login-form">

                <div style="margin-left: 15%;margin-right: 15%;">
                    <div style="margin-top:30px;color: #1F2D3D;font-size: 14px; ">
                        <div style="width: 200px;" prop="orgName">
                            机构名称：
                        </div>
                        <el-form-item prop="orgName" style="margin-left: 130px; margin-top: -25px;"  >
                            <el-input label="机构名称" v-model="regForm.orgName" placeholder="请输入机构名称" ></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div style="margin-left: 15%;margin-right: 15%;">
                    <div style="margin-top:30px; ">
                        <div style="width: 200px;color: #1F2D3D;font-size: 14px;" prop="orgCode">
                            统一社会信用代码：
                        </div>
                        <el-form-item prop="orgCode" style="margin-left: 130px; margin-top: -25px;">
                            <el-input label="统一社会信用代码" v-model="regForm.orgCode" placeholder="请输入统一社会信用代码" ></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div style="margin-left: 15%;margin-right: 15%;">
                    <div style="margin-top:30px; ">
                        <div style="width: 200px;color: #1F2D3D;font-size: 14px;">
                            营业执照图片：
                        </div>
                        <div style="margin-left: 130px; margin-top: -25px;">
                            <el-upload
                                    class="upload-demo"
                                    :auto-upload="true"
                                    :action="'https://api.gycidian.com/api/localStorage/pictures'"
                                    :on-preview="handlePreview"
                                    :on-remove="handleRemove"
                                    :on-success="handleSuccess"
                                    :on-error="handleError"
                                    :file-list="fileData"
                                    list-type="picture"
                            >
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </div>
                    </div>
                </div>
                <div style="margin-left: 15%;margin-right: 15%;">
                    <div style="margin-top:30px; ">
                        <div style="width: 200px;color: #1F2D3D;font-size: 14px;">
                            机构简介：
                        </div>
                        <el-form-item style="margin-left: 130px; margin-top: -25px;">
                            <el-input v-model="regForm.orgIntroduce" rows="6" type="textarea" placeholder="机构简介" />
                        </el-form-item>
                    </div>
                </div>
                <div style="margin-left: 15%;margin-right: 15%;">
                    <div style="margin-top:30px; ">
                        <div style="width: 200px;color: #1F2D3D;font-size: 14px;">
                            联系人：
                        </div>
                        <el-form-item style="margin-left: 130px; margin-top: -25px;">
                            <el-input v-model="regForm.contactor" placeholder="联系人" />
                        </el-form-item>
                    </div>
                </div>
                <div style="margin-left: 15%;margin-right: 15%;">
                    <div style="margin-top:30px; ">
                        <div style="width: 200px;color: #1F2D3D;font-size: 14px;">
                            联系电话：
                        </div>
                        <el-form-item style="margin-left: 130px; margin-top: -25px;">
                            <el-input v-model="regForm.contactorMobile" placeholder="联系电话" />
                        </el-form-item>
                    </div>
                </div>
                <div style="margin-left: 15%;margin-right: 15%;">
                    <div style="margin-top:30px; ">
                        <div style="width: 200px;color: #1F2D3D;font-size: 14px;">
                            联系地址：
                        </div>
                        <el-form-item style="margin-left: 130px; margin-top: -25px;">
                            <el-input v-model="regForm.contactorAddr" placeholder="联系地址" />
                        </el-form-item>
                    </div>
                </div>
                <div style="margin-left: 15%;margin-right: 15%;">
                    <div style="margin-top:30px; ">
                        <div style="width: 200px;color: #1F2D3D;font-size: 14px;">
                            邮箱：
                        </div>
                        <el-form-item style="margin-left: 130px; margin-top: -25px;">
                            <el-input v-model="regForm.emailAddr" placeholder="邮箱" />
                        </el-form-item>
                    </div>
                </div>

                <el-form-item style="margin-top: 40px;text-align: center;justify-content: center;">
                    <el-button :loading="loading" size="medium" type="primary" style="width:30%;" @click="handleLogin">
                        <span v-if="!loading">确认提交资料</span>
                        <span v-else>提 交 中...</span>
                    </el-button>
                </el-form-item>
                <div style="width:100%;">
                    <img src="../../assets/org.png">
                </div>
           </el-form>
        </el-main>
        </el-container>
        <el-footer>
            <div>©2021 北京慈典信息服务有限公司  <a target="_blank" href="https://beian.miit.gov.cn/" style="color: #FFFFFF;margin-left: 15px;text-decoration:none;" >ICP备案：京ICP备2021027898号</a>

                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802037567" style="margin-left: 15px;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                    <img src="../../assets/beian.png" style="float:left;"/>
                    <p style="float:left;height:20px;margin-left: 15px;margin: 4px 0px 0px 0px; color:#FFFFFF;">京公网安备 11010802037567号</p>
                </a>

            </div>
        </el-footer>
    </div>
</template>

<script>
//import { mapGetters } from 'vuex'
import axios from 'axios'


export default {
  name: "orgInfo",
  data(){
      return{
          regForm: {
              fileData: [],
              selimgpath: '',
              orgCode: '',
              licensePath: '',
              code: '',
              uuid: '',
              orgName: '',
              orgIntroduce: '',
              contactor: '',
              contactorMobile: '',
              contactorAddr: '',
              emailAddr: ''
          },
          loginRules: {
              orgName: [{ required: true, trigger: 'blur', message: '机构名称不能为空！' }],
              orgCode: [{ required: true, trigger: 'blur', message: '统一信用代码不能为空！' }],
              licensePath: [{ required: true, trigger: 'blur', message: '营业执照图片不能为空' }],
              contactor: [{ required: true, trigger: 'blur', message: '联系人不能为空' }],
              contactorMobile: [{ required: true, trigger: 'blur', message: '联系电话不能为空' }],
              contactorAddr: [{ required: true, trigger: 'blur', message: '联系地址不能为空' }],
              emailAddr: [{ required: true, trigger: 'blur', message: '邮箱不能为空' }]
          },
          loading: false

      }
  },
  computed: {
    //...mapGetters([
    //  'imagesUploadApi',
    //  'baseApi'
    //])
  },
  methods:{
      clickhome() {
          console.log('===goHome===')
          this.$router.push({
                  name: 'Home'
              }
          );
      },
      handleLogin(){
           if(!this.regForm.licensePath){
               this.$message({
                   type: 'info',
                   message: '请上传图片'
               })
               return;
           }
        this.$refs.regForm.validate(valid => {
          const user = {
            orgName: this.regForm.orgName,
            licensePath: this.regForm.licensePath,
            orgCode: this.regForm.orgCode,
            contactor: this.regForm.contactor,
            contactorMobile: this.regForm.contactorMobile,
            contactorAddr: this.regForm.contactorAddr,
            emailAddr: this.regForm.emailAddr,
            orgIntroduce: this.regForm.orgIntroduce
          }

          if (valid) {
            this.loading = true

              // if(this.licensePath == null){
              //
              // }
            axios.post('https://api.gycidian.com/api/mobileapi/addRegisterOrg', user).then(response => {
              if (response.status === 201) {
                this.$alert('入驻申请提交成功,审核结果在1-3个工作日内以邮件方式发到您的邮箱，请注意查收。', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$message({
                      type: 'info',
                      message: `欢迎回来`
                    })
                    this.$router.push('/')
                  }
                })
              }
            }, response => {
              console.log('==axios==error')
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },

      handleRemove(file, fileList) {
          console.log('===handleRemove file==' + file.name + '=fileList=' + fileList + '=fileList.size=' + fileList.length)
          for (var i = 0; i < fileList.length; i++) {
              console.log(fileList[i].name + fileList[i].url)
          }
          this.selimgpath = ''
      },
      handlePreview(file) {
          // console.log('===handlePreview=file==' + file)
      },
      handleSuccess(response, file, fileList) {
          // console.log('===handleSuccess=response=' + JSON.stringify(response))

          this.selimgpath = 'https://api.gycidian.com/file/images/' + response.realName
          this.regForm.licensePath = this.selimgpath
      },
      handleError(err, file, fileList) {
          console.log('===handleError=err=' + err)
      }
  }
}
</script>

<style scoped>
    @import "../home.css";
    .goorg{
        width: 137px;
        height: 100px;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        display: table-cell;
        vertical-align: middle;
        background: #0015DF !important;
    }
    .gologin{
        width: 170px;
        height: 100px;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        display: table-cell;
        vertical-align: middle;
        cursor: pointer;
    }
    .gohome{
        width: 70px;
        height: 100px;
        cursor: pointer;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        display: table-cell;
        vertical-align: middle;
        z-index: 999;
    }
</style>
