<template>
    <div style="background: #F1F2F8">
        <div class="topc">
            <img class="image" style="margin-left: 20%;"  src="../assets/logo.png" @click="goHome">

            <div style="margin-top: -80px;margin-left: 50%">
                <input style="margin-top: 20px;width: 550px;height: 50px;font-size: 20px;"  type="text" v-model="renew_amount" maxlength="1000" placeholder="请输入项目名称" @keyup.enter="findTar"/>
                <button style="border-radius: 10px;width: 120px;height: 50px;margin-left: 1%;background-color: #FF7D14;color: #FFFFFF ;"  name="button" value="" @click="findTar">查一查</button>
            </div>
        </div>
        <el-main style="height: 100%;background: #FFFFFF;margin-left: 20%;margin-right: 20%;margin-top: 30px;border-radius: 5px;">

            <div style="margin-right: 30%;font-size:12px;color:#0377F9 ;background: #F2F6FF;width: 100%">慈典为您搜索到{{tmpbigData.length}}个符合条件的公益项目</div>
            <div>
                <div v-for="(item,index) in tmpbigData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" :key="index" style="height: 196px;">
                    <div @click="toDetail(item.charitableId)" style="cursor: pointer;">
                    <div v-if="index>0" style=" border-top: 1px solid #ddd;">

                    </div>
                    <div style="display: flex; margin-top: 15px;">
                        <div>
                            <img src="../assets/listicon.png">
                        </div>
                        <div  style="margin-left: 1%;font-size: 16px;color: rgba(102, 102, 102, 1);font-weight: 600;">
                             {{item.proName}}
                        </div>

                    </div>
                    <div style="">
                        <div style="width: 220px;">
                            <img style="width: 210px;height: 120px;margin-top: 20px" :src="item.proImgSrc">
                        </div>
                        <div style="margin-left: 250px; margin-top: -120px;display: flex">
                            <div >
                                <img src="../assets/jigou.png">
                            </div>
                            <div style="color: rgba(102, 102, 102, 1);font-size: 14px;margin-left: 10px;">
                                发起机构：{{item.startOrg}}
                            </div>
                        </div>
                        <div style="margin-left: 250px; margin-top: 10px;display: flex">
                            <div >
                                <img src="../assets/addr.png">
                            </div>
                            <div style="color: rgba(102, 102, 102, 1);font-size: 14px;margin-left: 10px;">
                                项目地域：{{item.proArea}}
                            </div>
                        </div>
                        <div style="margin-left: 250px; margin-top: 10px;display: flex">
                            <div >
                                <img src="../assets/addr.png">
                            </div>
                            <div style="color: rgba(102, 102, 102, 1);font-size: 14px;margin-left: 10px;">
                                服务方式：{{item.serviceMeans}}
                            </div>
                        </div>
                        <div style="margin-left: 250px; margin-top: 10px;display: flex">
                            <div >
                                <img src="../assets/addr.png">
                            </div>
                            <div style="color: rgba(102, 102, 102, 1);font-size: 14px;margin-left: 10px;">
                                项目周期：{{item.psoDate}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </div>
            <div v-if="pvData.length > 0">
                <el-pagination style="text-align: center;justify-content: center;align-items: center;"
                               background
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page="currentPage"
                               :page-sizes="pageSizes"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="totalCount"
                >
                </el-pagination>
            </div>
            <div v-else style="height: 1040px" class="numdata">
                <div style="align-items: center;justify-content: center;">
                    <img src="../assets/defaultimg.png">
                </div>
                <div style="color: #666666;font-size: 14px;">
                    抱歉，没有查询到您要搜索的项目, 请重新输入
                </div>
            </div>
        </el-main>
<!--        <el-footer >©2021 北京慈典信息服务有限公司  ICP备案：京ICP备2021027898号</el-footer>-->
    </div>


</template>

<script>
    import axios from "axios";
export default {
    name: "resultList",
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            pageSizes: [20, 50],
            totalCount: 1,
            pvData: [],
            renew_amount: ""
        }
    },
    mounted() {
        if (this.$route.params.pvData != null) {
            this.pvData = JSON.parse(this.$route.params.pvData)
            this.totalCount = this.pvData.length
            this.renew_amount = JSON.parse(decodeURI(this.$route.params.keyword))
            console.log(JSON.parse(decodeURI(this.$route.params.keyword)))
            console.log(this.$route.params.keyword)
        }
        // this.findTar()
    },

    methods: {
        goHome() {
            this.$router.push({
                    name: 'Home'
                }
            );
        },
        toDetail(row) {
            // console.log('==push proj=item=' + JSON.stringify(row))
             const new_page = this.$router.resolve({
              // this.$router.push({
                            name: 'projdetail',
                            query: {
                                projitem: JSON.stringify(row)
                            }
                        }
                    );
             window.open(new_page.fullPath,'_blank')
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.currentPage = 1;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        findTar() {
            let that = this;
            if (this.renew_amount.length < 1) {
                alert('请输入检索关键字');
                return
            }

            axios.get('https://api.gycidian.com/api/mobileapi/getProjInfoOnlyByKeys',
                {
                    params: {
                        blurry: this.renew_amount
                    }
                }
            ).then(response => {

                that.pvData = response.data.content
                this.totalCount = this.pvData.length
                //console.log(that.pvData)
                // this.$router.push({
                //     name: 'resultList',
                //     params: {
                //         pvData: response.data.content
                //     }
                // })

            }, response => {
                console.log('error=' + response)
            })
        }
    },
    computed:{
        tmpbigData:{
            get:function () {
                return this.pvData;
            }
        },
        screenhight:{
            get:function () {
                console.log(document.body.scrollHeight )
                console.log(document.body.clientHeight  )
                console.log(window.screen.availHeight  )
                return window.screen.height+'px';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "home.css";
</style>
