// 适配 Nginx 反向代理
const baseUrl = process.env.VUE_APP_BASE_API === '/' ? '' : process.env.VUE_APP_BASE_API
const api = {
    state: {
        // 图片上传
        imagesUploadApi: baseUrl + '/api/localStorage/pictures',
        // swagger
        swaggerApi: baseUrl + '/swagger-ui.html',
        // 文件上传
        fileUploadApi: baseUrl + '/api/localStorage',
        // baseUrl，
        baseApi: baseUrl
    }
}

export default api
