<template>
  <div class="home">
    <div class="topc">
      <img class="image" style="margin-left: 20%; "  src="../assets/logo.png" @click="goHome">
    </div>
     <div style="margin-top: -100px;margin-left: 65%">
         <div class="gohome" >首页</div>
         <div class="goorg" @click="orgReg">机构申请入驻</div>
         <div class="gologin" style="padding-right: 18%;" @click="orgLogin('http://platform.gycidian.com/')" >机构登录</div>
     </div>
    <div class="container">
<!--      <div class="projectOrg">-->
<!--        <div class="project" :style="projectV" @click="findProject">查项目</div>-->
<!--        <div style="margin-left: 5px">|</div>-->
<!--        <div class="org" :style="proOrgV" @click="findOrg">查机构</div>-->
<!--      </div>-->
      <img style="width: 346px;height: 38px;margin-left: 42%;margin-right: 30%;margin-top: 10%;" src="../assets/query.png">
      <div style="margin-top: 20px; ">

          <!-- <select class="myselect" name="请选择" -->
          <!--         id="0" -->
          <!--         @change="selectd" -->
          <!--         v-model="current"> -->
          <!--   <option v-for="(item,index) in findlist" -->
          <!--           :key="index" -->
          <!--           :value="item.id" -->
          <!--           :data-rid='item.rid'>{{item.name}}</option> -->
          <!-- </select> -->
          <input class="form-control index-searchkey" type="text" v-model="renew_amount" maxlength="1000" :placeholder="keyword" @keyup.enter="findTar"/>
          <button class="button" name="button" value="" @click="findTar" >查一查</button>
            <!-- <button class="button" name="button" value="" @click="faindFavorite">测查收藏</button> -->
            <!-- <button class="button" name="button" value="" @click="addFavorite">测加收藏</button> -->
            <!-- <button class="button" name="button" value="" @click="delFavorite">测删收藏</button> -->
      </div>
    </div>

    <div class="myother" >
        <div style="margin-left: 38%;margin-right: 30%">
          <img style="height: 81px;width: 435px" src="../assets/remmend.png">
        </div>
        <div class="main">
            <div v-for="(item, index) in gftjData"  :key="index" style="cursor: pointer;background: #FFFFFF;padding: 20px;width: 386px;height: 358px; border: 5px solid  rgba(183, 183, 183, 0.3)" @click="toProjdetail(item.charitableId)">
                <div>
                    <img style="width: 386px;height: 250px;" :src="item.proImgSrc !=''?item.proImgSrc:'../assets/default.png'">
                </div>
                <div style="color: #333333;font-size: 16px;margin-top: 5px;font-weight: 600">
                    {{item.proName}}
                </div>
                <div class="jiesho" style="color: #666666;font-size: 14px;margin-top: 10px;">
                    {{item.proJieshao}}
                </div>
                <div style="color: rgba(153, 153, 153, 1);font-size: 14px;margin-top: 10px;text-align: right">
                    {{item.psoDate}}
                </div>
            </div>
        </div>


    </div>
      <div class="myother" >
          <div style="margin-left: 38%;margin-right: 30%">
              <img style="height: 81px;width: 435px" src="../assets/info.png">
          </div>
          <div class="main">
              <div v-for="(item, index) in newsData"  :key="index" style="cursor: pointer;background: #FFFFFF;padding: 20px;width: 591px;height: 100px; border: 5px solid  rgba(183, 183, 183, 0.3)" @click="toDetail(item.id)" >
                  <div style="display: flex">
                      <div>
                          <img style="width: 16px;height: 16px;vertical-align: middle" src="../assets/infoicon.png">
                      </div>
                      <div style="color: rgba(51, 51, 51, 1);font-size: 16px;margin-top: 5px;font-weight: 600;margin-left: 10px">
                          {{item.title}}
                      </div>
                  </div>
                  <div class="jiesho" style="color: #666666;font-size: 14px;margin-top: 10px;">
                      {{item.newsabstract}}
                  </div>
                  <div style="color: rgba(153, 153, 153, 1);font-size: 14px;margin-top: 10px;text-align: right">
                      {{item.updateTime}}
                  </div>

              </div>
          </div>
      </div>

    <el-footer>

            <div>©2021 北京慈典信息服务有限公司  <a target="_blank" href="https://beian.miit.gov.cn/" style="color: #FFFFFF;margin-left: 15px;text-decoration:none;" >ICP备案：京ICP备2021027898号</a>

                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802037567" style="margin-left: 15px;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                    <img src="../assets/beian.png" style="float:left;"/>
                    <p style="float:left;height:20px;margin-left: 15px;margin: 4px 0px 0px 0px; color:#FFFFFF;">京公网安备 11010802037567号</p>
                </a>

        </div>

    </el-footer>
  </div>


</template>

<script>
import axios from 'axios'
export default {
  name: 'Home',
  data(){
    return {
      target: 1, // 1 查项目 2 查机构
      stypes: 1,
      gftjData: [],
      newsData: [],
      renew_amount: "",
      current: 1,
      findlist: [{ id: 1, name: '按名称' }, { id: 2, name: '按领域'}, { id: 3, name: '按发起机构'}, { id: 4, name: '按地域'}],
      zxfbV: {'border-bottom-style': 'ridge', 'color': '#00BFFF' },
      gyzxV: {'color': '#B0C4DE'},
      projectV: {'border-bottom-style': 'ridge'},
      proOrgV: {},
      show: true,
      rightoddnumber: 1,
      leftoddnumber:50,
      keyword: "请输入项目名称"

    }
  },
  mounted () {
    this.zxfb();
    this.gyzx();
  },
  methods:{
    selectd(){
       console.log(this.current)
    },
    goHome() {
      console.log('===goHome===')
      this.$router.push({
                name: 'Home'
              }
      );
    },
    clickhome(){

    },
      addFavorite() {
          axios.post('https://api.gycidian.com/api/mobileapi/addFavoriteProj',
              {
                  userMobile: '13523055742',
                  charitableId: 5
              }
          ).then(response => {

              this.$router.push({
                  name: 'Home',
                  params: {
                      pvData: JSON.stringify(response.data)
                  }
              })
          }, response => {
              console.log('error=' + response)
          })
      },
      delFavorite() {
          axios.post('https://api.gycidian.com/api/mobileapi/delFavoriteProj',
              {
                  userMobile: '13523055742',
                  charitableId: 5
              }
          ).then(response => {

              this.$router.push({
                  name: 'Home',
                  params: {
                      pvData: JSON.stringify(response.data)
                  }
              })
          }, response => {
              console.log('error=' + response)
          })
      },
      faindFavorite() {
          axios.get('https://api.gycidian.com/api/mobileapi/getUserFavoriteProjs',
              {
                  params: {
                      userId: 1
                  }
              }
          ).then(response => {

              console.log('=====2====' + JSON.stringify(response.data))
              this.$router.push({
                  name: 'resultList',
                  params: {
                      pvData: JSON.stringify(response.data)
                  }
              })
          }, response => {
              console.log('error=' + response)
          })
      },
    toProjdetail(item){
        const new_page = this.$router.resolve({
                // this.$router.push({
                name: 'projdetail',
                query: {
                    projitem: JSON.stringify(item)
                }
            }
        );
        window.open(new_page.fullPath,'_blank')
      // this.$router.push({
      //           name: 'projdetail',
      //           query: {
      //             projitem: JSON.stringify(item)
      //           }
      //         }
      // );
    },
    findTar(){
      if (this.renew_amount.length < 1) {alert('请输入检索关键字'); return}
      if (this.target == 1) {
        // 1 查项目
        axios.get('https://api.gycidian.com/api/mobileapi/getProjInfoOnlyByKeys',
            {
              params: {
                blurry: this.renew_amount
              }
            }
        ).then(response => {
          this.$router.push({
            name: 'resultList',
            params: {
              pvData: JSON.stringify(response.data.content),
              keyword: JSON.stringify(this.renew_amount)
            }
          })
            // this.$router.push({
            //     name:'renew_amount',
            //     params:{
            //         keyword: JSON.stringify(this.renew_amount)
            //     }
            // })
        }, response => {
          console.log('error=' + response)
        })
      } else {
        // 2查机构
        axios.get('https://api.gycidian.com/api/mobileapi/getOrgInfoOnlyByKeys',
            {
              params: {
                blurry: this.renew_amount
              }
            }
        ).then(response => {
          //console.log('===response.content===' + JSON.stringify(response.data))
          this.$router.push({
            name: 'orgRsltList',
            params: {
              pvData: JSON.stringify(response.data)
            }
          })
        }, response => {
          console.log('error=' + response)
        })
      }
    },
    zxfb(){
      this.zxfbV = {
          'border-bottom-style': 'ridge',
          'color': '#00BFFF'
      };
      this.gyzxV = {'color': '#B0C4DE'}
      this.show = true
      axios.get('https://api.gycidian.com/api/mobileapi/getRecommendProjs',
          {
            params: {
            }
          }
      ).then(response => {
        // console.log('===getRecommendProjs===' + JSON.stringify(response.data.content))
        this.gftjData = response.data.content
      }, response => {
        console.log('error=' + response)
      })
    },
    gyzx(){
      this.gyzxV = {
          'border-bottom-style': 'ridge',
          'color': '#00BFFF'
      };
      this.zxfbV = {'color': '#B0C4DE'}
      this.show = false
      axios.get('https://api.gycidian.com/api/mobileapi/getNewsInfo',
          {
            params: {
            }
          }
      ).then(response => {
        // console.log('===getNewsInfo===' + JSON.stringify(response.data.content))
        this.newsData = response.data.content
      }, response => {
        console.log('error=' + response)
      })
    },
    toDetail(item){
        const new_page = this.$router.resolve({
                // this.$router.push({
                name: 'newsdetail',
                query: {
                    id: JSON.stringify(item)
                }
            }
        );
        window.open(new_page.fullPath,'_blank')
      // console.log('==push=item=' + item)
      // this.$router.push({
      //     name: 'newsdetail',
      //     params: {
      //       newsitem: JSON.stringify(item)
      //     }
      //   }
      // );
    },
    orgReg(){
      this.$router.push('/orgInfo/orgInfo');
    },
    orgLogin(thrUrl){
      console.log("跳转到第三方页面")
      window.open(thrUrl,'_blank');
    },
    findProject(){
      this.target = 1
      this.projectV = {
        'border-bottom-style': 'ridge'
      };
      this.proOrgV = {}
      this.findlist =  [{ id: 1, name: '按名称' }, { id: 2, name: '按领域'},  { id: 3, name: '按发起机构'},{ id: 4, name: '按地域'}];
      this.keyword ='请输入项目名称';
    },
    findOrg(){
      this.target = 2
      this.proOrgV = {
        'border-bottom-style': 'ridge'
      };
      this.projectV = {};
      this.findlist =  [{ id: 1, name: '按名称' }, { id: 2, name: '按领域'}, { id: 4, name: '按地域'}];
      this.keyword = '请输入机构名称'
    }
  }

}
</script>
<style lang="scss" scoped>
 @import "home.css";
 .goorg{
     width: 170px;
     height: 100px;
     text-align: center;
     color: #FFFFFF;
     font-size: 16px;
     font-weight: 600;
     display: table-cell;
     vertical-align: middle;
     cursor: pointer;
 }
 .gologin{
     width: 170px;
     height: 100px;
     text-align: center;
     color: #FFFFFF;
     font-size: 16px;
     font-weight: 600;
     display: table-cell;
     vertical-align: middle;
     cursor: pointer;
 }
 .gohome{
     width: 71px;
     height: 100px;
     background: #0015DF !important;
     text-align: center;
     color: #FFFFFF;
     font-size: 16px;
     font-weight: 600;
     display: table-cell;
     vertical-align: middle;
     z-index: 999;
 }

</style>
