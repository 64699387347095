<template>

 <div style="background: #F1F2F8">
   <div class="topc">
     <img class="image" style="margin-left: 20%;"  src="../assets/logo.png" @click="goHome">

   </div>
   <el-main style="height: 100%;background: #FFFFFF;margin-left: 20%;margin-right: 20%;margin-top: 30px;border-radius: 5px;">
     <div style="margin-left: 1%;font-size: 18px;color: #333333;font-weight: 600;">
       {{projdtl.proName}}
     </div>
     <div style="margin-left: 1%;font-size: 16px;color: #333333;font-weight: 600;margin-top: 20px;">
       项目简介
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;line-height: 25px;">
       {{projdtl.proJieshao}}
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;">
        <img :src="projdtl.proImgSrc">
     </div>
     <div style="margin-left: 1%;font-size: 16px;color: #333333;font-weight: 600;margin-top: 20px;">
       其他信息
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         项目类型：
       </div>
       <div style="flex: 90%;">
         <span v-for="(item,index) in projdtl.cates" :key="index">
          {{index>0 && catesList.indexOf(item.id)>-1?'、':''}} {{index>-1 && catesList.indexOf(item.id)>-1?item.name:''}}
         </span>
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         发起机构：
       </div>
       <div style="flex: 90%;">
         {{projdtl.startOrg}}
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         执行机构：
       </div>
       <div style="flex: 90%;">
         {{projdtl.executeOrg}}
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         项目地域：
       </div>
       <div style="flex: 90%;">
         {{projdtl.proArea}}
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         服务对象：
       </div>
       <div style="flex: 90%;">
         {{projdtl.proCrowd}}
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         服务方式：
       </div>
       <div style="flex: 90%;">
         {{projdtl.serviceMeans}}
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         项目周期：
       </div>
       <div style="flex: 90%;">
         {{projdtl.psoDate}}
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         联系人：
       </div>
       <div style="flex: 90%;">
         {{projdtl.contactPerson}}
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         联系电话：
       </div>
       <div style="flex: 90%;">
         {{projdtl.telephone}}
       </div>
     </div>
     <div style="margin-left: 1%;font-size: 14px;color: #666666;margin-top: 20px;display: flex;line-height: 20px;">
       <div style="flex: 5%">
         联系地址：
       </div>
       <div style="flex: 90%;">
         {{projdtl.orgAddr}}
       </div>
     </div>
   </el-main>
   <el-footer>
     <div>©2021 北京慈典信息服务有限公司  <a target="_blank" href="https://beian.miit.gov.cn/" style="color: #FFFFFF;margin-left: 15px;text-decoration:none;" >ICP备案：京ICP备2021027898号</a>

       <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802037567" style="margin-left: 15px;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
         <img src="../assets/beian.png" style="float:left;"/>
         <p style="float:left;height:20px;margin-left: 15px;margin: 4px 0px 0px 0px; color:#FFFFFF;">京公网安备 11010802037567号</p>
       </a>

     </div>
   </el-footer>
 </div>
</template>

<script>
  import axios from "axios";
export default {
  name: "projdetail",
  data(){
    return {
      projdtl: [],
      projdtl_id: 0,
      catesList: ''

    }
  },
  mounted() {
    // console.log(this.$route.query.projitem)
    this.projdtl_id = JSON.parse(this.$route.query.projitem)
    // console.log(this.projdtl_id)
    this.findByid()
    this.findByPid()

  },
  methods: {
    goHome() {
      this.$router.push({
                name: 'Home'
              }
      );
    },
    //
   findByid(){
      let that = this
     axios.get('https://api.gycidian.com/api/mobileapi/getProjInfoById',
             {
               params: {
                 charitableId: this.projdtl_id
               }
             }
     ).then(response => {
        // console.log(response.data)

       that.projdtl = response.data
       // console.log(that.projdtl.propName)

     }, response => {
       console.log('error=' + response)
     })
   },
    findByPid(){
      let that = this
      axios.get("https://api.gycidian.com/api/mobileapi/getCateByPid").then((res => {
        // console.log(res.data)
        let datas = res.data;
        for(let item in datas){
          // console.log(datas[item].id)
          if(item>0){
            that.catesList += ','
          }
          that.catesList += datas[item].id
        }
      }))
    }


  }
}
</script>

<style lang="scss" scoped>
  @import "home.css";

</style>
