<template>
  <div >
    <el-header>
      <div class="headers">
        <img class="image"  src="../assets/logo02.svg" @click="goHome">
      </div>
    </el-header>
    <el-main style="height: 100%">
      <div>
        <div class="input-group" style="margin-top: 50px">
          <input class="form-control index-searchkey" type="text" v-model="renew_amount" maxlength="1000" placeholder="请输入机构名称" />
          <button class="button" name="button" value="" @click="findTar">查一查</button>
        </div>
      </div>
      <div v-if="tmpbigData.length < 1" style="margin-left: 30%;margin-right: 30%">根据条件检索到0条记录，请您更换输入查询条件。</div>
      <div style="margin-left: 20%;margin-right: 20%">
        <ul v-for="(item,index) in tmpbigData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" :key="index">
          <li>

            <el-row>
              <el-col span="6" >
                <el-form-item style="font-size: 34px;font-weight: 600" required>机构名称：</el-form-item>
              </el-col>
              <el-col span="18" >
                <el-form-item style="font-size: 34px;font-weight: 600" required>{{item.orgName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row style="display: flex; font-size: 16px;" :gutter="2">
              <el-col style="flex: 10%" span="6">
                <el-form-item style="font-size: 24px;font-weight: 600" required>机构介绍：</el-form-item>
              </el-col>
              <el-col style="flex: 10%;" span="18" >
                <el-form-item>{{item.orgIntroduce}}</el-form-item>
              </el-col>
            </el-row>
            <el-row style="display: flex;height: 25px" :gutter="2">
              <el-col style="flex: 10%" span="6">
                <el-form-item style="font-weight: 600" required>联系人：</el-form-item>
              </el-col>
              <el-col style="flex: 90%" span="18" >
                <el-form-item>{{item.contactor}}</el-form-item>
              </el-col>
            </el-row>
            <el-row style="display: flex; height: 25px" :gutter="2">
              <el-col style="flex: 10%" span="6">
                <el-form-item style="font-weight: 600">联系电话：</el-form-item>
              </el-col>
              <el-col style="flex: 90%" span="18" >
                <el-form-item>{{item.contactorMobile}}</el-form-item>
              </el-col>
            </el-row>
            <el-row style="display: flex; height: 25px" :gutter="2">
              <el-col style="flex: 10%" span="6">
                <el-form-item style="font-weight: 600">联系地址：</el-form-item>
              </el-col>
              <el-col style="flex: 90%" span="18" >
                <el-form-item>{{item.contactorAddr}}</el-form-item>
              </el-col>
            </el-row>

          </li>
        </ul>

      </div>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pageSizes"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="totalCount"
      >
      </el-pagination>
    </el-main>
    <el-footer >
      <div>©2021 北京慈典信息服务有限公司  <a target="_blank" href="https://beian.miit.gov.cn/" style="color: #FFFFFF;margin-left: 15px;text-decoration:none;" >ICP备案：京ICP备2021027898号</a>

        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802037567" style="margin-left: 15px;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="../assets/beian.png" style="float:left;"/>
          <p style="float:left;height:20px;margin-left: 15px;margin: 4px 0px 0px 0px; color:#FFFFFF;">京公网安备 11010802037567号</p>
        </a>

      </div>
    </el-footer>
  </div>


</template>

<script>
import axios from "axios";

export default {
  name: "orgRsltList",
  data(){
    return{
      currentPage: 1,
      pageSize: 1,
      pageSizes: [20, 50],
      totalCount: 1,
      pvData: [],
      renew_amount: ""
    }
  },
  mounted () {
    //console.log('==this.$route.params.pvData==' + this.$route.params.pvData)
    if (this.$route.params.pvData != null) {
      this.pvData = JSON.parse(this.$route.params.pvData)
      this.totalCount = this.pvData.length
    }
  },
  methods: {
    goHome() {
      this.$router.push({
                name: 'Home'
              }
      );
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    findTar() {
      let that = this;
      if (this.renew_amount.length < 1) {
        alert('请输入检索关键字');
        return
      }

      // 2查机构
      axios.get('https://api.gycidian.com/api/mobileapi/getOrgInfoOnlyByKeys',
              {
                params: {
                  blurry: this.renew_amount
                }
              }
      ).then(response => {
        //console.log('===response.content===' + JSON.stringify(response.data))
        that.pvData =  response.data;
        this.totalCount = this.pvData.length
        that.totalCount = response.data.length
      }, response => {
        console.log('error=' + response)
      })
    }
  },
  computed:{
    tmpbigData:{
      get:function () {
        return this.pvData;
      }
    }
  }
}
</script>

<style scoped>

.headers{
  background: linear-gradient(#4169E1,#87CEFA);
  height: 120px;
}

</style>
